<template>
  <transition name='fade'>
    <div v-if='showSidepanel'
      class='sidepanel fixed top-0 right-0 h-full bg-white shadow-2xl px-2 lg:px-8 pb-2 lg:pb-16 text-left z-30 overflow-y-auto'
      :class='topPadding'
      :style='panelFullOrHalf'>
      <button
        v-if='showCopyControl'
        ref='shareIcon'
        @click='copyUrlToClipboard'
        class='cursor-pointer fixed z-20 hover:bg-gray-100'
        style='top: 2rem;'
        :style='copyControlLocation'>
        <share-icon />
      </button>
      <button
        v-if='!isMobile && showToggleControl'
        ref='fullScreenToggle'
        @click='toggleFullscreen'
        class='cursor-pointer fixed z-20 hover:bg-gray-100' style='top: 2rem; right: 6rem;'>
        <component :is='toggleFullScreenIcon'/>
      </button>
      <button
        ref='closeIcon'
        @click='sidepanelClose'
        @keydown.esc='sidepanelClose'
        class='hover:bg-gray-100 fixed z-20 sidepanel-close-button'>
        <x-icon class='cursor-pointer '/>
      </button>
      <portal-target name='sidepanel-header'></portal-target>
      <slot />
      <component :is='contentComponent' />

      <loading-indicator  v-if='isLoading' />
      <div v-if='isLoading'
        class='overlay'
        :class='displayedCSS'
        :style='panelFullOrHalf'></div>

      <div
        class='fixed bottom-0 right-0'
        :style='panelFullOrHalf'>
        <portal-target name='sidepanel-footer'></portal-target>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions }          from 'vuex'
import { ShareIcon, XIcon }              from '@vue-hero-icons/outline'
import { LoginIcon, LogoutIcon }         from '@vue-hero-icons/solid'
import LoadingIndicator                  from '@/components/LoadingIndicator.vue'

export default {
  name: 'Sidepanel',
  components: {
    LoadingIndicator,
    LoginIcon,
    LogoutIcon,
    ShareIcon,
    XIcon,
  },
  computed: {
    ...mapState([
      'isLoading',
      'isSidepanelFullWidth',
      'showSidepanel',
      'sidepanelContentComponent',
      'sidepanelContentDirectory',
    ]),
    contentComponent ()  {
      return this.importComponent(this.sidepanelContentComponent, this.sidepanelContentDirectory)
    },
    displayedCSS () {
      return (this.isLoading) ? 'displayed' : ''
    },
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone')
    },
    copyControlLocation () {
      return (this.isMobile || !this.showToggleControl) ? 'right: 6rem;' : 'right: 10rem;'
    },
    panelFullOrHalf () {
      return (this.isSidepanelFullWidth || this.isMobile) ? 'width:100%;' : 'resize:horizontal; width:63%; min-width: 700px;'
    },
    showCopyControl () {
      // right now, only show the control for investments from the dashboard
      // add in other types of sidepanels later
      return this.$route.name === 'Dashboard'
    },
    showToggleControl () {
      return this.$route.name !== 'DrilldownContainer'
    },
    topPadding () {
      return (['investment-drilldown', 'entity-details'].includes(this.sidepanelContentComponent)) ? 'pt-0' : ''
    },
    toggleFullScreenIcon () {
      return (this.isSidepanelFullWidth) ? 'logout-icon' : 'login-icon'
    },
    urlToCopy () {
      let params = this.$route.query
      return `${process.env.VUE_APP_CLIENT_BASE_URL}/drilldown?investment_id=${params.investment_id}&tab=${params.tab}`
    }
  },
  watch: {
    showSidepanel: {
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.closeIcon.focus()
          })
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'sidepanelClose',
      'setSidepanelFullscreen',
      'setSidepanelHalf',
    ]),
    toggleFullscreen () {
      if (this.isSidepanelFullWidth) {
        this.setSidepanelHalf()
      } else {
        this.setSidepanelFullscreen()
      }
    },
    copyUrlToClipboard () {
      this.$copyText(this.urlToCopy).then(() => {
        this.$message({
          type: 'success',
          message: 'Copied to clipboard',
        })
      })
    },
    importComponent (componentName, directory) {
      if (directory) {
        return () => import(`@/${directory}/${componentName}.vue`)
      } else {
        return 
      }
    }
  },
}
</script>

<style lang='scss' scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all .2s;
}

div.fade-enter,
div.fade-leave-to {
  opacity: 0;
  right: -420px;
}

.overlay {
  @apply bg-cover fixed top-0 right-0 h-full z-20;
  background-color: #223645;
  // transition: opacity 0.2s ease-in-out;
  // transition: visibility 0.2s ease-in-out;
}
.overlay.displayed {
  opacity: 0.8;
}

.sidepanel-close-button {
  top: 0.5rem;
  right: 0.5rem;
}

@media screen and (min-width: 1024px) {
  .sidepanel-close-button {
    top: 2rem;
    right: 2rem;
  }
}

</style>

<style lang='scss'>
// Helper class for content in sidepanel to be flush
.sidepanel-horizontal-flush {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

@media (min-width: 1024px) {
  .sidepanel-horizontal-flush {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}
</style>
